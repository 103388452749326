// Styles
import { CustomStartsWrapper } from "./custom-starts.style";
// Components
import { PopupDropdown } from "../PopupDropdown";
// Custom hooks
import { useIdeaFlow } from "../../hooks/use-idea-flow";
// Utils
import {
  getCustomStartTypeByUrlName,
  getCustomStartUrlNameByType,
} from "@considr-it/storied-shared";
import { trackEvent } from "@considr-it/storied-shared";

interface CustomStartOption {
  value: string;
  label: string;
  description: string;
}

const customStartsData: CustomStartOption[] = [
  {
    value: null,
    label: "Custom",
    description: "Works for everything! Just state your goal",
  },
  {
    value: "note",
    label: "Take a Note",
    description: "Capture your creative ideas before they're lost",
  },
  {
    value: "lipost",
    label: "LinkedIn Post",
    description: "Craft the perfect LinkedIn post",
  },
  {
    value: "recap",
    label: "Meeting Recap",
    description: "Synthesize your ideas and get to clear actions",
  },
  {
    value: "prep",
    label: "Meeting Agenda",
    description: "Organize your ideas so you're clear & concise",
  },
  {
    value: "email",
    label: "Email",
    description: "Talk through it like you're with a friend",
  },
  {
    value: "eod",
    label: "End of Day",
    description: "Take a walk to assess and improve your day",
  },
  {
    value: "producthero",
    label: "Product Hero",
    description: "Envision your customer on a hero's journey",
  },
];

export const CustomStarts = ({
  dropdownOnly = false,
  onSelectCb = null,
  externalOpen = false,
  openToTop = false,
  placeholderText = "",
  ...rest
}) => {
  const { setCustomStartType, customStartType } = useIdeaFlow();

  const handleDropdownSelect = (customStartOption: CustomStartOption) => {
    const customStartType = getCustomStartTypeByUrlName(
      customStartOption.value
    );

    setCustomStartType(customStartType);
    if (onSelectCb) {
      onSelectCb(customStartType);
    }

    trackEvent("changed_custom_start", { customStart: customStartType });
  };

  const currentStartPos = customStartsData.find(
    (v) => getCustomStartUrlNameByType(customStartType) === v.value
  );

  const selectedCustomStart = currentStartPos || customStartsData[0];

  return (
    <CustomStartsWrapper {...rest}>
      <PopupDropdown
        options={customStartsData}
        onSelect={handleDropdownSelect}
        defaultOption={selectedCustomStart}
        popupTitle="Start with a template"
        popupDescription="Select an option and we'll structure the questions and writing for your needs."
        dropdownOnly={dropdownOnly}
        externalOpen={externalOpen}
        placeholderText={placeholderText}
        openToTop={openToTop}
      />
    </CustomStartsWrapper>
  );
};
